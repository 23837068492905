import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Axios from "axios"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"

import Thankyou from "../../images/uploads/thank-you-envlop.svg"
import { Fade } from "react-reveal"


const ContactForm = () => {
  const [loading, setLoading] = useState(false)
  const [messageBody, setMessageBody] = useState("")
  const [countrycode, setCountrycode] = useState("")
  const [success, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  // document.addEventListener('touchstart', function(event) {
  //   var target = event.target;
  //   if (target.tagName === 'INPUT') {
  //     target.focus(); 
  //   }
  // }, false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm()

  const onSubmit = data => {
    // if (e) e.preventDefault();
    const postUrl = `${process.env.API_LINK}api/contact/user/create`

    setLoading(true)

    const body = {
      fullname: data.fullname,
      companyname: data.companyname,
      email: data.email,
      countryCode: countrycode,
      phone: data.phone,
      message: messageBody,
    }
    Axios.post(postUrl, body)
      .then(result => {
        if (result.data.status) {
          setSuccessMessage(true)
        } else {
          setSuccessMessage(false)
          setErrorMessage("Something bad happened while saving the data!!")
        }
        setSuccess(true)
        reset({})
        setMessageBody("")
        setTimeout(() => {
          setSuccess(false)
        }, 2000)
        setLoading(false)
      })
      .catch(err => {
        setSuccess(false)
        setSuccessMessage(false)
        setErrorMessage(`Something bad happened while saving the data\n${err}`)
        setLoading(false)
      })
  }

  var pattern =  /\S+@\S+\.\S+/;
  var patternnumber = /^[0-9]*$/
  return (
    <>
      <div className="px-5 py-9 mmsd:px-8 sm:py-10  bg-background-quaternary dec-lead-form-inner">
        {!success ? (
          <>
            <p className="px-0 xl:px-4 mb-6 lg:text-p2 text-white font-worksans-normal text-center">
              Let's hear what you are looking for and we'll help you find the
              best solution!
            </p>
            <form
              className="w-full form-desk"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="mb-3-3 lg:mb-4-2">
                <input
                  {...register("fullname", { required: true })}
                  className={`${
                    errors?.fullname?.type === "required" ? "err-box" : ""
                  } form-input px-6 py-3-1 w-full text-p3 focus:outline-none text-white bg-transparent border border-celeste border-opacity-20`}
                  type="text"
                  placeholder="Name*"
                />
              </div>
              <div className="mb-3-3 lg:mb-4-2">
                <input
                  {...register("companyname")}
                  className="form-input  px-6 py-3-1 w-full text-p3 focus:outline-none text-white font-worksans-normal bg-transparent border border-celeste border-opacity-20 "
                  type="text"
                  placeholder="Company"
                />
              </div>

              <div className="mb-3-3 lg:mb-4-2 relative">
                <input
                  {...register("email", {
                    required: true,
                    validate: value => pattern.test(value),
                  })}
                  className={`${
                    errors.email ? "err-box" : ""
                  } form-input px-6 py-3-1 w-full text-p3 focus:outline-none text-white font-worksans-normal bg-transparent border border-celeste border-opacity-20`}
                  type="text"
                  placeholder="Email*"
                />
              </div>
              <div className="mb-3-3 lg:mb-4-2">
                <div className="flex items-center text-white font-worksans-normal bg-transparent border border-celeste border-opacity-20 decode-mobile-field">
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: true,
                      validate: value => patternnumber.test(value),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        country="us"
                        preferredCountries={["us", "au", "gb", "ae", "de"]}
                        value={value}
                        // onChange={onChange}
                        onChange={(phoneNumber, selectedCountry) => {
                          onChange(phoneNumber)
                          setCountrycode(selectedCountry?.dialCode)
                        }}
                        className={`${
                          errors.phone ? "err-box" : ""
                        }form-input font-worksans-normal w-full text-p3 focus:outline-none bg-transparent flex-1`}
                        inputStyle={{
                          borderColor: errors.phone && "red",
                        }}
                        type="text"
                        placeholder="Phone number*"
                      />
                    )}
                  />
                  {/* {errors.phone && (
                        <p className="err-box">Invalid Phone</p>
                      )} */}
                </div>
              </div>

              <div className="mb-3-3 lg:mb-4-2">
                <textarea
                  name="message"
                  value={messageBody}
                  onChange={e => setMessageBody(e.target.value)}
                  class=" form-input px-6 py-2-3 w-full text-p4 lg:text-p3 text-white font-worksans-normal bg-transparent border border-celeste border-opacity-20 focus:outline-none"
                  placeholder="Message*"
                  type="text"
                  rows="4"
                ></textarea>
              </div>
              <button
                class={`mt-2 px-6 w-full py-3-1 text-p3 focus:outline-none text-white font-poppings-bold bg-cyan shadow-4 hover:shadow-none transition duration-300 ease-in-out ${
                  loading ? "cursor-wait opacity-50" : ""
                } `}
              >
                {" "}
                Submit
              </button>
            </form>
          </>
        ) : (
          <div className="h-full flex items-center justify-center">
            {successMessage ? (
              <div className="text-center w-9/10">
                <Fade bottom> 
                <img className="pb-5-1 lg:pb-35 m-auto" src={Thankyou} />
                </Fade>
                <Fade bottom delay={200}>
                <h2 className="text-white font-poppings-bold text-h4 lg:text-h3">
                  Thank You
                </h2>
                </Fade>
                <Fade bottom delay={400}>
                <p className="text-white font-worksans-normal text-rp3 lg:text-p2">
                  For your message. We look forward to meeting you and will be
                  in touch soon.
                </p>
                </Fade>
              </div>
            ) : (
              errorMessage
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ContactForm
